import React, {useEffect, useState} from "react";
import CameraInput from "../components/CameraInput";
import {useHistory} from "react-router-dom";
import axios from "axios";

const uploadPhotos = (photos) => {
	return axios.post(window.req_params.uploader, {photos, data: window.req_params});
};

const uploadPhoto = (photo) => {
	return axios.post(window.req_params.uploader, {photo, data: window.req_params});
}

export default function CameraScreen(props) {
	const [front, setFront] = useState(true);
	const [images, setImages] = useState([]);

	const history = useHistory();
	useEffect(() => {
		if (images.length === 1) {
			props.setLoading(true);
			setFront(false);
			uploadPhoto(images[0]).then(() => props.setLoading(false));

		}
		if (images.length === 2) {
			props.setLoading(true);
			uploadPhoto(images[1]).then(() => {
				props.setLoading(false);
				if (window.top) {
					window.top.location = `${window.req_params.redirect}`;
				}
			})

		}
	}, [images]);


	const savePhoto = (photo) => {
		setImages(
			[
				...images,
				photo
			]
		);
	};
	return (
		<>
			<CameraInput front={front} savePhoto={savePhoto} />
		</>
	)
}

