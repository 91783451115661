import React from "react";
import GridLoader from 'react-spinners/MoonLoader'

export default function Animation({front, cameraLoaded, fillColor, simple}) {

	const fill = fillColor || 'white';


	if (simple) {
		if (front) {
			return (
				<svg viewBox="0 0 246 144" focusable="false" className="front">
					<g fill="none" fillRule="evenodd">
						<g transform="translate(-37 -174)">
							<g transform="translate(38 175)">
								<rect id="Rectangle" width="244" height="142" rx="8" fill="#fff"></rect>
								<path id="Rectangle"
									  d="m8 0h228c4.4183-8.1162e-16 8 3.5817 8 8v21h-244v-21c-5.4108e-16 -4.4183 3.5817-8 8-8z"
									  fill="#004E5F" fill-opacity=".04"></path>
								<g transform="translate(101 45)" fill="#E6EBF4">
									<rect id="Rectangle" width="127" height="16"></rect>
									<rect id="Rectangle" y="28" width="109" height="16"></rect>
									<rect id="Rectangle" y="56" width="90" height="16"></rect>
								</g>
								<rect id="a" transform="translate(50.5 81) scale(-1 1) translate(-50.5 -81)" x="16"
									  y="45" width="69" height="72" fill="#E6EBF4"></rect>
								<circle className="f17sij70" cx="50" cy="73" r="16" fill="#004E5F"></circle>
								<path className="f17sij70"
									  d="m50 93c15.82 0 22.227 11.731 22.933 24h-45.867c0.70658-12.27 7.1136-24 22.933-24z"
									  fill="#004E5F"></path>
								<rect width="244" height="142" rx="8" stroke="#222329" strokeWidth="2"></rect>
							</g>
						</g>
					</g>
				</svg>
			)
		} else {
			return (
				<svg viewBox="0 0 246 144" focusable="false" className="back">
					<g fill="none" fillRule="evenodd">
						<g transform="translate(-37 -174)">
							<g transform="translate(38 175)">
								<rect id="Rectangle" width="244" height="142" rx="8" fill="#fff"></rect>
								<path id="Rectangle"
									  d="m8 0h228c4.4183-8.1162e-16 8 3.5817 8 8v21h-244v-21c-5.4108e-16 -4.4183 3.5817-8 8-8z"
									  fill="#004E5F" fill-opacity=".04"></path>
								<rect id="Rectangle" x="16" y="39" width="212" height="16" fill="#E6EBF4"></rect>
								<rect id="Rectangle" x="16" y="63" width="212" height="16" fill="#E6EBF4"></rect>
								<rect id="a" x="109" y="94" width="119" height="35" fill="#E6EBF4"></rect>
								<rect width="244" height="142" rx="8" stroke="#222329" strokeWidth="2"></rect>
							</g>
						</g>
					</g>
				</svg>
			)
		}

	}

	if (front) {
		return (
			<div className="iw2dhaj" aria-hidden="true">
				{cameraLoaded ? null : <GridLoader css={{position: 'absolute'}}/>}
				{
					cameraLoaded ? (
						<>
						<svg className="s1vyr0as" viewBox="0 0 320 568" preserveAspectRatio="xMidYMid slice" focusable="false">
							<defs>
								<mask id="mask">
									<rect width="100%" height="100%" fill="#fff"></rect>
									<rect x="16" y="20%" width="288" height="192" rx="24"></rect>
								</mask>
							</defs>
							<rect width="100%" height="100%" mask="url(#mask)" fill-opacity="0.72"></rect>
						</svg>
						<svg className="s7e9nf9 default" width="418" height="256" viewBox="0 0 418 256" fill="none"
							 focusable="false">
							<rect opacity="0.4" x="2" y="2" width="414" height="252" rx="6" stroke="white"
								  strokeWidth="4"></rect>
						</svg>
						<div className="s31ynh2 default">
							<svg width="262" height="152" viewBox="0 0 262 152" fill="none" focusable="false">
								<g className="f1ttuk76">
									<path opacity="0.4"
										  d="M214.397 20.4615H161.136C158.962 20.4615 157.2 22.2311 157.2 24.4139V25.2784C157.2 27.4613 158.962 29.2308 161.136 29.2308H214.397C216.571 29.2308 218.333 27.4613 218.333 25.2784V24.4139C218.333 22.2311 216.571 20.4615 214.397 20.4615Z"
										  fill={fill}></path>
									<path opacity="0.4"
										  d="M258.064 40.9231H161.136C158.962 40.9231 157.2 42.6926 157.2 44.8754V45.74C157.2 47.9228 158.962 49.6923 161.136 49.6923H258.064C260.238 49.6923 262 47.9228 262 45.74V44.8754C262 42.6926 260.238 40.9231 258.064 40.9231Z"
										  fill={fill}></path>
									<path opacity="0.4"
										  d="M170.73 61.3846H161.136C158.962 61.3846 157.2 63.1541 157.2 65.3369V66.2015C157.2 68.3843 158.962 70.1538 161.136 70.1538H170.73C172.904 70.1538 174.667 68.3843 174.667 66.2015V65.3369C174.667 63.1541 172.904 61.3846 170.73 61.3846Z"
										  fill={fill}></path>
									<path opacity="0.4"
										  d="M199.842 61.3846H190.247C188.073 61.3846 186.311 63.1541 186.311 65.3369V66.2015C186.311 68.3843 188.073 70.1538 190.247 70.1538H199.842C202.016 70.1538 203.778 68.3843 203.778 66.2015V65.3369C203.778 63.1541 202.016 61.3846 199.842 61.3846Z"
										  fill={fill}></path>
									<path opacity="0.4"
										  d="M228.953 61.3846H219.359C217.185 61.3846 215.422 63.1541 215.422 65.3369V66.2015C215.422 68.3843 217.185 70.1538 219.359 70.1538H228.953C231.127 70.1538 232.889 68.3843 232.889 66.2015V65.3369C232.889 63.1541 231.127 61.3846 228.953 61.3846Z"
										  fill={fill}></path>
									<path opacity="0.4" d="M3.05176e-05 0H133.911V152H3.05176e-05V0Z" fill={fill}></path>
									<path opacity="0.4"
										  d="M66.9556 90.6154C83.8371 90.6154 97.5223 76.874 97.5223 59.9231C97.5223 42.9722 83.8371 29.2308 66.9556 29.2308C50.0741 29.2308 36.3889 42.9722 36.3889 59.9231C36.3889 76.874 50.0741 90.6154 66.9556 90.6154Z"
										  fill="#222328"></path>
									<path opacity="0.4" fillRule="evenodd" clip-rule="evenodd"
										  d="M66.9556 102.308C97.077 102.308 109.276 126.596 110.622 151.999H23.2889C24.6347 126.596 36.8342 102.308 66.9556 102.308Z"
										  fill="#222328"></path>
								</g>
							</svg>
						</div>
						</>
					) : null
				}
			</div>
		)
	} else {
		return (
			<div className="iw2dhaj" aria-hidden="true">
				{cameraLoaded ? null : <GridLoader css={{position: 'absolute'}}/>}
				{
					cameraLoaded ? (
						<>
						<svg className="s1vyr0as" viewBox="0 0 320 568" preserveAspectRatio="xMidYMid slice" focusable="false">
							<defs>
								<mask id="mask">
									<rect width="100%" height="100%" fill="#fff"></rect>
									<rect x="16" y="20%" width="288" height="192" rx="24"></rect>
								</mask>
							</defs>
							<rect width="100%" height="100%" mask="url(#mask)" fill-opacity="0.72"></rect>
						</svg>
						<svg className="s7e9nf9 default" width="418" height="256" viewBox="0 0 418 256" fill="none"
							 focusable="false">
							<rect opacity="0.4" x="2" y="2" width="414" height="252" rx="6" stroke="white"
								  strokeWidth="4"></rect>
						</svg>
						<div className="f1qv9pa6 default">
							<div className="f1i29ehj">
								<svg viewBox="0 0 246 144" aria-hidden="true" focusable="false" className="front">
									<g fill="none" fillRule="evenodd">
										<g transform="translate(-37 -174)">
											<g transform="translate(38 175)">
												<rect id="Rectangle" width="244" height="142" rx="8" fill="#fff"></rect>
												<path id="Rectangle"
													  d="m8 0h228c4.4183-8.1162e-16 8 3.5817 8 8v21h-244v-21c-5.4108e-16 -4.4183 3.5817-8 8-8z"
													  fill="#004E5F" fill-opacity=".04"></path>
												<g transform="translate(101 45)" fill="#E6EBF4">
													<rect id="Rectangle" width="127" height="16"></rect>
													<rect id="Rectangle" y="28" width="109" height="16"></rect>
													<rect id="Rectangle" y="56" width="90" height="16"></rect>
												</g>
												<rect id="a" transform="translate(50.5 81) scale(-1 1) translate(-50.5 -81)" x="16"
													  y="45" width="69" height="72" fill="#E6EBF4"></rect>
												<circle className="f17sij70" cx="50" cy="73" r="16" fill="#004E5F"></circle>
												<path className="f17sij70"
													  d="m50 93c15.82 0 22.227 11.731 22.933 24h-45.867c0.70658-12.27 7.1136-24 22.933-24z"
													  fill="#004E5F"></path>
												<rect width="244" height="142" rx="8" stroke="#222329" strokeWidth="2"></rect>
											</g>
										</g>
									</g>
								</svg>
								<svg viewBox="0 0 246 144" focusable="false" className="back">
									<g fill="none" fillRule="evenodd">
										<g transform="translate(-37 -174)">
											<g transform="translate(38 175)">
												<rect id="Rectangle" width="244" height="142" rx="8" fill="#fff"></rect>
												<path id="Rectangle"
													  d="m8 0h228c4.4183-8.1162e-16 8 3.5817 8 8v21h-244v-21c-5.4108e-16 -4.4183 3.5817-8 8-8z"
													  fill="#004E5F" fill-opacity=".04"></path>
												<rect id="Rectangle" x="16" y="39" width="212" height="16" fill="#E6EBF4"></rect>
												<rect id="Rectangle" x="16" y="63" width="212" height="16" fill="#E6EBF4"></rect>
												<rect id="a" x="109" y="94" width="119" height="35" fill="#E6EBF4"></rect>
												<rect width="244" height="142" rx="8" stroke="#222329" strokeWidth="2"></rect>
											</g>
										</g>
									</g>
								</svg>
							</div>
						</div>
						</>
					) : null
				}
			</div>

		)
	}
}
