import './App.css';
import React, {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import SelectType from "./Screens/SelectType";
import CameraScreen from "./Screens/CameraScreen";
import LoadingOverlay from 'react-loading-overlay';
import GridLoader from 'react-spinners/MoonLoader'
import EntryPointScreen from "./Screens/EntryPointScreen"

function useQuery() {
  return new URLSearchParams(window.location.search);
}

function App() {
  const [loading, setLoading] = useState(false);
  const query =   useQuery();
  const data = localStorage.getItem('req');
  if (data) {
    window.req_params = JSON.parse(data);
  }
  let lang = query.get("lang") || localStorage.getItem('lang') || 'en';
  localStorage.setItem('lang', lang);
  window.translate = (string) => {
    return window['tr'][lang][string] || string;
  };
  return (
      <LoadingOverlay
          active={loading}
          fadeSpeed={200}
          spinner={<GridLoader color={'green'} size={100}  />}
      >
      <div>
        <div className={'container'}>
          <Router>
            <div>
              <Switch>
                <Route path={"/:id/:details/:uploader/:redirect"}>
                  <EntryPointScreen  setLoading={setLoading}/>
                </Route>
                <Route path="/camera">
                  <CameraScreen setLoading={setLoading}/>
                </Route>
                <Route path="/doctype">
                  <SelectType/>
                </Route>
              </Switch>
            </div>
          </Router>
        </div>
   </div>
   </LoadingOverlay>
  )
}

export default App;
