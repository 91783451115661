import React, {useState} from "react";
import {ListGroup, Row, Col, Button} from "react-bootstrap";
import idcard from "../id-card.svg";
import passport from "../passport.svg";
import driversid from "../drivers-license.svg";
import residence from "../residence-permit.svg";
import { useHistory, useParams } from "react-router-dom";



function SelectType() {
	const docs = [
		{
			name: window.translate('ID Card'),
			logo: idcard,
		},
		{
			name:  window.translate('Passport'),
			logo: passport
		},
		{
			name:  window.translate('Residence permit'),
			logo: residence
		},
		{
			name:  window.translate("Driver's license"),
			logo: driversid
		}
	]

	const history = useHistory();
	const [selected, setSelected] = useState('');
	let { id, params } = useParams();

	return (
		<div className={'main'}>
			<div className={'row'}>
				<div className={'col-md-12 d-flex justify-content-center align-items-center'}>
					<h4>{window.translate("Select ID type")}</h4>
				</div>
				<div className={'col-md-12 d-flex justify-content-center align-items-center'}>
					<p className={'text-center'}>{ window.translate("Which government-issued identity document would you like to use?")}</p>
				</div>
			</div>
			<div className={'row'}>
				<div className={'col-md-12'}>
					<ListGroup className={'doc-type'}>
						{
							docs.map((doc) => (
								<ListGroup.Item className={selected  === doc.name ? 'selected' : ''} key={doc.name} onClick={() => setSelected(doc.name)} >
									<Row>
										<Col className={'col-8 d-flex justify-content-start align-items-center'}>
											<span>{doc.name}</span>
										</Col>
										<Col className={'col-4 d-flex justify-content-end align-items-center'}>
											<img src={doc.logo}/>
										</Col>
									</Row>
								</ListGroup.Item>
							))
						}
					</ListGroup>
				</div>
			</div>
			<Row className={'mt-4'}>
				<Col lg={'12'} className={'d-flex justify-content-center align-items-center'}>
					<Button variant="success"  block onClick={() => history.push("/camera")}>
						{window.translate('Continue')}
					</Button>
				</Col>
			</Row>

		</div>
	)
}

export default SelectType;
