import React, {useEffect, useState} from "react";
import Webcam from "react-webcam";
import Animation from "./Animation";
import {Button, Row, Col} from "react-bootstrap";
import {useDropzone} from 'react-dropzone';

const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});


const videoConstraints = {
	width: 600,
	height: 400,
	facingMode: "environment"
};


export default function CameraInput({savePhoto, front}) {
	const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({accept: 'image/*', maxFiles: 1});
	const [hasCamera, setHasCamera] = useState(true);
	const textBack = [window.translate("Turn your document around"),
		window.translate("Turn your document around and take a photo of the other side.")];
	const textFront = [window.translate("Take a photo of your document photo page"), window.translate("Make sure your document photo page is clear and readable.")]

	const webcamRef = React.useRef(null);

	useEffect(() => {
		if (acceptedFiles[0]) {
			toBase64(acceptedFiles[0]).then((b) => {
				savePhoto(b);
			})
		}
	}, [acceptedFiles])

	const fallbackToUpload = () => {
			setCameraLoaded(true);
			setHasCamera(false);
	};
	const takePhoto = React.useCallback(
		() => {

			if (hasCamera) {
				const imageSrc = webcamRef.current.getScreenshot();
				savePhoto(imageSrc);
			} else {
				open();
			}

		},
		[webcamRef, savePhoto, hasCamera]
	);


	const [cameraLoaded, setCameraLoaded] = useState(false);


	return (
		<div className={'container'} style={{maxWidth: 600, padding: 0}}>
			<div className={`row ${hasCamera ? 'desktop-button' : ''}`}>
				<div className={'col-md-12 d-flex justify-content-center align-items-center'}>
					<h4 className={'text-center'}>{front ? textFront[0] : textBack[0]}</h4>
				</div>
				<div className={'col-md-12 d-flex justify-content-center align-items-center'}>
					<p className={'text-center'}>{front ? textFront[1] : textBack[1]}</p>
				</div>
			</div>

			{
				hasCamera ? (<div className={'sgirz5a'}>
					<div className={'camera-container'}>
						<Webcam
							ref={webcamRef}
							facingMode={'environment'}
							onUserMedia={(stream) => setCameraLoaded(true)}
							onUserMediaError={() => fallbackToUpload()}
							videoConstraints={videoConstraints}
							className={'camera'}
							audio={false}
							mirrored={false}
							screenshotFormat="image/jpeg"

						/>
					</div>

					<>
						<Animation front={front} cameraLoaded={cameraLoaded} />

						<div className="scvu48f"><h4>{front ? textFront[0] : textBack[0]}</h4><p>{front ? textFront[1] : textBack[1]}</p></div>
						<div className="sc7u7vd">
							<button type="button" onClick={() => takePhoto()} className="b1c7psjy s1o96zdj" data-test-take-photo="true">{window.translate('Take photo')}</button>
						</div>
					</>

				</div>) : (<div {...getRootProps({className: 'dropzone'})}>
					<input {...getInputProps()} />
					<Animation front={front} simple={true} fillColor={'grey'} cameraLoaded={true}/>
				</div>)
			}

			<Row className={`mt-4 ${hasCamera ? 'desktop-button' : null}`}>
				<Col md-12>
					<Button variant="success" block onClick={() => takePhoto()}>{hasCamera ? window.translate('Take Photo') : window.translate('Select Photo')}</Button>
				</Col>
			</Row>
		</div>
	)
}
